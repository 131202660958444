import { makeStyles, pallete, styles } from "app/styles";

export const useMoneyHubStyles = makeStyles()((theme) => {
  return {
    icon: {
      fontSize: "2.5rem",
      color: "white",
    },
    cardTooltip: {
      padding: "10px",
      borderRadius: "8px",
      background: "rgba(255, 255, 255, 0.80)",
      boxShadow: "0px 0px 12px 0px rgba(42, 23, 76, 0.10)",
      backdropFilter: "blur(6px)",
    },
    tooltipBox: {
      display: "flex",
      flexDirection: "row",
    },
    topHeadingWeb: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    gridItems: {
      // paddingLeft: "20px",
      [theme.breakpoints.down("lg")]: {
        padding: 0,
        margin: "10px 0",
      },
    },
    iconsBtn: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      // lineHeight: "140%",
      padding: "10px 20px",
      borderRadius: "8px",
      textTransform: "none",
    },
    startIconBox: {
      height: "24px",
      width: "24px",
    },
    startIconBoxB: {
      backgroundColor: pallete.primaryGreen,
      borderRadius: "50%",
      padding: "5px",
      height: "15px",
      width: "15px",
    },
    commonCardsHBox: {
      ...styles.flexDRS,
      padding: "10px",
    },
    commonCards: {
      padding: "12px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      [theme.breakpoints.down("lg")]: {
        margin: "10px 0",
      },
    },
    commonCardsTable0: {
      padding: "12px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      margin: "20px 0",
    },
    mainBox: {
      backgroundColor: "#FCFBFE",
      height: "91vh",
      padding: "10px 10px",
      [theme.breakpoints.down("lg")]: {
        height: "100%",
        padding: "100px 5px",
      },
    },
    tabsText: {
      fontSize: "16px",
      fontWeight: 600,
      color: pallete.selections.blue,
      textTransform: "none",
    },
    StatsCardContainer: {
      paddingBottom: "20px",
    },
  };
});

export const useMoneyHubCredentialStyles = makeStyles()((theme) => {
  return {
    btntabTwo: {
      ...styles.homeButton,
      backgroundColor: pallete.primaryPurple,
      color: pallete.primaryWhite,
      margin: "20px 0",
    },
    copyBoxParent: {
      ...styles.flexDRS,
      padding: "5px 0",
    },
    copyBox: {
      display: "flex",
      flexDirection: "row",
    },
    credCard: {
      width: "100%",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      padding: "20px 10px",
    },
    credentialsColumn: {
      ...styles.flexDRS,
      margin: "20px 10px",
    },
    credentialsCardContainer: {
      ...styles.flexDRS,
      margin: "20px 10px",
    },
    addButton: {
      ...styles.webButton,
    },
  };
});

export const useMoneyHubTestFormStyles = makeStyles()((theme) => {
  return {
    mainBoxForm: {
      paddingTop: "20px",
    },
    buttonBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: "20px 10px",
    },
    gridItemPadding: {
      padding: "5px 10px",
    },
    routeName: {},
    bottomText: {
      position: "absolute",
      bottom: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    termsText: {
      color: pallete.primaryBlack,
      textDecorationLine: "underline",
      margin: "auto 0",
    },
    checkBoxB: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    mainBox: {
      backgroundColor: "#FCFBFE",
      height: "91vh",
      padding: "30px 50px",
      overflowY: "auto",
      [theme.breakpoints.down("lg")]: {
        height: "100%",
        padding: "80px 15px",
      },
    },
    topHeadingWeb: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    bottomBTNBox: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    bottomBtns: {
      width: "100%",
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "2px",
      background: "#FFF",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      textTransform: "none",
    },
    iconGA: {
      height: "18px",
      width: "18px",
    },
    orText: {
      fontWeight: "500",
      lineHeight: "28px",
      color: "#36205F80",
    },
    orBox: {
      display: "flex",
      flexFlow: "row",
    },
    submitBtn: {
      ...styles.webButton,
      padding: "10px 60px",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    rightGrid: {
      padding: "10px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        // height: "100%",
        padding: "30px 15px",
      },
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    mainGrid: {},
    formBox: {},
    leftGrid: {
      padding: "30px 100px",
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100%", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    credCard: {
      width: "100%",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      padding: "20px 10px",
    },
  };
});

export const useMoneyHubOrdersStyles = makeStyles()((theme) => {
  return {
    downloadCsvBtn: {
      textTransform: "none",
      margin: "0 10px",
    },
    iconWeb: {
      height: "24px",
      width: "24px",
    },
    empytBox: {
      height: "100px",
      width: "100vh",
      ...styles.flexDCC,
      alignItems: "center",
    },
    searchBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: "10px",
    },
    modalBox: {
      width: "700px",
      padding: "10px",
    },
    buttonsTab1: {
      ...styles.flexDRS,
    },
    addButton: {
      ...styles.webButton,
    },
    tabsText: {
      fontSize: "16px",
      fontWeight: 600,
      color: pallete.selections.blue,
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
      },
    },
    commonCards: {
      padding: "12px",
      margin: "20px 0px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
    },
    mainBox: {
      backgroundColor: "#FCFBFE",
      height: "91vh",
      padding: "30px 50px",
      overflowY: "auto",
      [theme.breakpoints.down("lg")]: {
        height: "100%",
        padding: "80px 15px",
      },
    },
    topHeadingWeb: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
  };
});

export const getStatusColor = (status: string): any => {
  switch (status) {
    case "-1":
      return pallete.selections.red;
    case "1":
      return pallete.selections.blue;
    case "2":
      return pallete.selections.green;
    default:
      return pallete.selections.red;
  }
};
