import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { pallete, styles } from "app/styles";
import { makeStyles } from "tss-react/mui";
import Loader from "app/components/unsorted/Loader";
import { useNavigate } from "react-router-dom";
import { getAxiosAPI, putAxiosAPI } from "app/utils/axiosApiRequests";
import { useNotification } from "app/providers";
import { SearchInput } from "app/components/inputs";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserDetails = ({ userId }: { userId: any }) => {
  const { success, error } = useNotification();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [userDetails, setUserDetails] = useState<any>({});
  const [userAccounts, setUserAccounts] = useState<any>([]);
  const [rolesList, setRolesList] = useState([]);
  const [updateRole, setUpdateRole] = useState("");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (userId) {
      if (value === 0) {
        getUserDetails();
        getUserRole();
      } else if (value === 1) {
        getBankDetails();
      }
    }
  }, [value, userId]);

  // console.log(updateRole);

  const getUserDetails = async () => {
    setLoading(true);
    await getAxiosAPI(`user/account/${userId}`, true)
      .then((res: any) => {
        // console.log(res.data.data);
        setUserDetails(res.data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        error("Failed to fetch user details ");
      });
  };
  const getUserRole = async () => {
    await getAxiosAPI(`user/roles`, true)
      .then((res: any) => {
        // console.log(res.data.data);
        setRolesList(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
        error("Failed to fetch user role ");
      });
  };

  const getBankDetails = async () => {
    setLoading(true);
    await getAxiosAPI(`user/bank-accounts/${userId}`, true)
      .then((res: any) => {
        console.log(res.data.data);
        setUserAccounts(res.data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        error("Failed to fetch user details ");
      });
  };

  const updateUserRole = async () => {
    setLoading(true);
    const payload = {
      role_id: updateRole,
    };
    await putAxiosAPI(`user/roles/${userId}`, payload, true)
      .then((res: any) => {
        console.log(res.data.data);
        setLoading(false);
        success("User role updated successfully");
        getUserDetails();
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        error("Failed to update user role ");
      });
  };

  return (
    <Box className={classes.mainBox}>
      <Loader isLoading={loading} />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Box className={classes.bredCrumBox}>
          <Typography
            variant="h4"
            color="primary"
            onClick={() => navigate(-1)}
            className={classes.bredText}
          >
            User List
          </Typography>
          <Typography
            variant="h4"
            color="primary"
            className={classes.topHeadingWeb}
          >
            /Details
          </Typography>
        </Box>

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            className={classes.tabsText}
            label="Account Details"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tabsText}
            label="Bank Details"
            {...a11yProps(1)}
          />
          <Tab
            className={classes.tabsText}
            label="Other Details"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Typography
          variant="h4"
          color="primary"
          sx={{ paddingBottom: "20px" }}
          className={classes.topHeadingWeb}
        >
          Account Details
        </Typography>
        <Box>
          <Grid container>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <Typography color="primary" variant="body2">
                Full Name
              </Typography>
              <TextField
                id="outlined-basic"
                label={userDetails.username ? "" : "Full Name"}
                variant="outlined"
                autoComplete="off"
                disabled={true}
                className={classes.inputBox}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                value={userDetails.username}
              />
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <Typography color="primary" variant="body2">
                Company Name
              </Typography>
              <TextField
                id="outlined-basic"
                label={userDetails.company_name ? "" : "Company Name"}
                variant="outlined"
                autoComplete="off"
                disabled={true}
                className={classes.inputBox}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                value={userDetails.company_name}
              />
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <Typography color="primary" variant="body2">
                Mobile Phone
              </Typography>
              <TextField
                id="outlined-basic"
                label={userDetails.mobile_no ? "" : "Mobile Phone"}
                variant="outlined"
                autoComplete="off"
                disabled={true}
                className={classes.inputBox}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                value={userDetails.mobile_no}
              />
            </Grid>

            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <Typography color="primary" variant="body2">
                Email
              </Typography>
              <TextField
                id="outlined-basic"
                label={userDetails.email ? "" : "Email"}
                variant="outlined"
                autoComplete="off"
                disabled={true}
                className={classes.inputBox}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                value={userDetails.email}
              />
            </Grid>

            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <Typography color="primary" variant="body2">
                Country,City
              </Typography>
              <TextField
                id="outlined-basic"
                label="Country,City"
                variant="outlined"
                autoComplete="off"
                disabled={true}
                className={classes.inputBox}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                value=""
              />
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <Typography color="primary" variant="body2">
                Address
              </Typography>
              <TextField
                id="outlined-basic"
                label="Address"
                variant="outlined"
                autoComplete="off"
                disabled={true}
                className={classes.inputBox}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                value=""
              />
            </Grid>

            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <Typography color="primary" variant="body2">
                User Role
              </Typography>
              <TextField
                id="outlined-basic"
                label={userDetails.username ? "" : "User Role"}
                variant="outlined"
                autoComplete="off"
                disabled={true}
                className={classes.inputBox}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                value={userDetails.role_name}
              />
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <Typography color="primary" variant="h6">
                Change Role
              </Typography>
              <SearchInput
                options={rolesList}
                label="Select Role"
                onSelect={(field: any, value: any) => setUpdateRole(value)}
                displayValue="name"
                formikValue="id"
              />
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.buttonBox}>
                <Button
                  disabled={updateRole ? false : true}
                  onClick={() => updateUserRole()}
                  variant="contained"
                  className={classes.submitBtn}
                >
                  Update Role
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid container>
          {userAccounts.length ? (
            userAccounts.map((item: any) => (
              <Grid
                key={item.id}
                item
                lg={6}
                xs={12}
                className={classes.cardsGird}
              >
                <Card className={classes.credCard}>
                  <Box className={classes.copyBoxParent}>
                    <Typography variant="h6" color="primary">
                      Holder Name
                    </Typography>
                    <Box className={classes.copyBox}>
                      <Typography
                        variant="body2"
                        color="primary"
                        sx={{ margin: "auto 5px" }}
                      >
                        {item.acc_holder_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.copyBoxParent}>
                    <Typography variant="h6" color="primary">
                      Account Number
                    </Typography>
                    <Box className={classes.copyBox}>
                      <Typography
                        variant="body2"
                        color="primary"
                        sx={{ margin: "auto 5px" }}
                      >
                        {item.acc_number}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.copyBoxParent}>
                    <Typography variant="h6" color="primary">
                      Swift Code
                    </Typography>
                    <Box className={classes.copyBox}>
                      <Typography
                        variant="body2"
                        color="primary"
                        sx={{ margin: "auto 5px" }}
                      >
                        {item.swift_code}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.copyBoxParent}>
                    <Typography variant="h6" color="primary">
                      IBAN (Country code + 21 numbers)
                    </Typography>
                    <Box className={classes.copyBox}>
                      <Typography
                        variant="body2"
                        color="primary"
                        sx={{ margin: "auto 5px" }}
                      >
                        {item.acc_iban_number}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.copyBoxParent}>
                    <Typography variant="h6" color="primary">
                      SWIFT{" "}
                    </Typography>
                    <Box className={classes.copyBox}>
                      <Typography
                        variant="body2"
                        color="primary"
                        sx={{ margin: "auto 5px" }}
                      >
                        BMCOMPTPL{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid xs={12} className={classes.noAccountFounBox}>
              <Typography variant="h5" color="primary">
                No Account Found!
              </Typography>
            </Grid>
          )}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Typography
          variant="h4"
          color="primary"
          className={classes.topHeadingWeb}
        >
          Comming Soon...
        </Typography>
      </CustomTabPanel>
    </Box>
  );
};

export default UserDetails;

const useStyles = makeStyles()((theme) => {
  return {
    submitBtn: {
      ...styles.webButton,
      padding: "10px 60px",
    },
    buttonBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: "20px 10px",
    },
    noAccountFounBox: {
      // ...styles.flexDRC,
    },
    copyBox: {
      display: "flex",
      flexDirection: "row",
    },
    copyBoxParent: {
      ...styles.flexDRS,
      padding: "5px 0",
    },
    credCard: {
      width: "100%",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      padding: "20px 10px",
    },
    cardsGird: {
      padding: "10px 20px",
    },
    gridItemPadding: {
      padding: "5px 10px",
    },
    label: {
      // Custom styles for the label
      color: "#B9B9B9 !important", // Change label text color
      // fontSize: '1rem', // Change font size
      "&.Mui-focused": {
        color: "blue", // Color of the label when the input is focused
      },
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    bredCrumBox: {
      display: "flex",
      flexDirection: "row",
    },
    mainBox: {
      backgroundColor: "#FCFBFE",
      height: "91vh",
      padding: "30px 50px",
      overflowY: "auto",
      [theme.breakpoints.down("lg")]: {
        height: "100%",
        padding: "80px 15px",
      },
    },
    tabsText: {
      fontSize: "16px",
      fontWeight: 600,
      color: pallete.selections.blue,
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
      },
    },
    bredText: {
      cursor: "pointer",
      "&:hover": {
        color: pallete.parimayBlue,
      },
    },
    topHeadingWeb: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
  };
});
