import React, {useState} from "react";
import {IconButton, Skeleton} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

type T_MenuItemOption = {
  onClickHandler: (data: any) => void;
  name: string;
  disabled?: boolean;
  icon?: React.ReactNode;
};

const defaultMenuItemOptionList: T_MenuItemOption[] = [
  {
    name: "No Options Available",
    onClickHandler: () => {},
    disabled: true,
  },
];

const DropdownMenuItemsSkeleton = (keyPrefix: string): React.JSX.Element[] => {
  const key: string = `${keyPrefix}-DROPDOWN-MENU-SKEL`;
  // const commonProps = { height: "1.5rem", sx: { margin: "5px" } };
  return [
    <MenuItem>
      <Skeleton key={`${key}-1`} variant="text" width={"80px"} />
    </MenuItem>,
    <MenuItem>
      <Skeleton key={`${key}-1`} variant="text" width={"80px"} />
    </MenuItem>,
  ];
};

const defaultGetMenuItemOptionsFn = (): Promise<T_MenuItemOption[]> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(defaultMenuItemOptionList), 500);
  });
};

type T_DropdownMenuItemsProps = {
  keyPrefix: string;
  menuItemOptions: T_MenuItemOptionList;
};

const DropdownMenuItems = ({
  keyPrefix,
  menuItemOptions,
}: T_DropdownMenuItemsProps): React.JSX.Element[] => {
  if (!menuItemOptions) {
    return DropdownMenuItemsSkeleton(keyPrefix);
  }

  return menuItemOptions.map((opt: T_MenuItemOption, index: number) => {
    const menuItemProps = {
      onClick: opt.onClickHandler,
      disabled: opt?.disabled,
      key: `${keyPrefix}-MENU-ITEM-${index}`,
    };

    return <MenuItem {...menuItemProps}>{opt.name}</MenuItem>;
  });
};

type T_DropdownMenuIconButtonProps = {
  keyPrefix: string;
  menuIsOpen: boolean;
  actionOptionOnClickHandler: (event: React.MouseEvent<HTMLElement>) => void;
  menuIcon: React.ReactNode;
};

const DropdownMenuIconButton = (
  opt: T_DropdownMenuIconButtonProps
): React.JSX.Element => {
  return (
    <IconButton
      id="demo-positioned-button"
      aria-controls={opt.menuIsOpen ? "demo-positioned-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={opt.menuIsOpen ? "true" : undefined}
      onClick={opt.actionOptionOnClickHandler}
      key={`${opt.keyPrefix}-ICON`}
    >
      {opt.menuIcon}
    </IconButton>
  );
};

type T_DropdownMenuComponentProps = {
  keyPrefix: string;
  anchorElement: undefined | HTMLElement;
  menuIsOpen: boolean;
  actionMenuOnCloseHandler: () => void;
  menuItemsList: React.JSX.Element[];
};

const DropdownMenuComponent = (
  opt: T_DropdownMenuComponentProps
): React.JSX.Element => {
  return (
    <Menu
      id={`${opt.keyPrefix}-positioned-menu`}
      aria-labelledby={`${opt.keyPrefix}-positioned-button`}
      anchorEl={opt.anchorElement}
      open={opt.menuIsOpen}
      onClose={opt.actionMenuOnCloseHandler}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      key={`${opt.keyPrefix}-MENU`}
    >
      {opt.menuItemsList}
    </Menu>
  );
};

export type T_MenuItemOptionList = T_MenuItemOption[] | undefined;

export type T_DropdownMenuButtonProps<Tdata = any> = {
  name?: string;
  keyPrefix: string;
  getMenuItemOptionsFn?: (data: Tdata) => Promise<T_MenuItemOptionList>;
  rowData?: Tdata;
  menuIcon?: React.ReactNode;
};

export const DropdownMenuButton = (
  opt: T_DropdownMenuButtonProps
): React.JSX.Element => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<undefined | HTMLElement>();
  const [menuItemOptions, setMenuItemOptions] =
    useState<T_MenuItemOptionList>();
  const getMenuItemOptionsFn =
    opt.getMenuItemOptionsFn || defaultGetMenuItemOptionsFn;
  const keyPrefix: string = `${opt.keyPrefix}-DMB`;

  const actionOptionOnClickHandler = (
    event: React.MouseEvent<HTMLElement>
  ): void => {
    setAnchorElement(event.currentTarget);
    setMenuIsOpen(true);
    getMenuItemOptionsFn(opt.rowData)
      .then((itemOptions: T_MenuItemOptionList) => {
        setMenuItemOptions(
          itemOptions && itemOptions?.length > 0
            ? itemOptions
            : defaultMenuItemOptionList
        );
      })
      .catch(() => setMenuItemOptions(defaultMenuItemOptionList));
  };

  const actionMenuOnCloseHandler = () => {
    setAnchorElement(undefined);
    setMenuIsOpen(false);
    setMenuItemOptions(undefined);
  };

  const dropdownMenuIconButtonProps: T_DropdownMenuIconButtonProps = {
    keyPrefix,
    menuIsOpen,
    actionOptionOnClickHandler,
    menuIcon: opt.menuIcon || <MoreVertIcon />,
  };

  const dropdownMenuComponentProps: T_DropdownMenuComponentProps = {
    keyPrefix,
    anchorElement,
    menuIsOpen,
    actionMenuOnCloseHandler,
    menuItemsList: DropdownMenuItems({ keyPrefix, menuItemOptions }),
  };

  return (
    <>
      {DropdownMenuIconButton(dropdownMenuIconButtonProps)}
      {DropdownMenuComponent(dropdownMenuComponentProps)}
    </>
  );
};
