import React, {useState} from "react";
import {FORM, validationSchema} from "app/components/inputs";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import * as Yup from "yup";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import {postAxiosAPI} from "../../../app/utils/axiosApiRequests";
import MessageAlert from "../../../app/components/unsorted/MessageAlert";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import {MuiPhone} from "../../../app/components/inputs/fields/MobileNumberWIthFlag";

Yup.addMethod(Yup.string, "phone_number", function (errorMessage) {
    return this.test("phone_number", errorMessage, (value: any) => {
        const phoneNumber = parsePhoneNumberFromString(value);
        return phoneNumber ? phoneNumber.isValid() : false;
    });
});

const schema = Yup
    .object({
        email: validationSchema.email(),
        company: Yup.string().required("Company Name is required"),
        phone_number: Yup.string()
        .required("Phone number is required"),
    }).required()


interface I_SignUpRequestFormValues {
    email: string
    company: string
    phone_number: string
}

export const SignupRequestForm = (): React.JSX.Element => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState<"success" | "error">("success");
    const [phone, setPhone] = useState<string>("");

    const onSubmitHandler = (values: any) => {
        //setTimeout(() => console.log("From Submit"), 2000);
        if (values.phone_number.length < 13){
             setSnackbarMessage("Phone Number should be at least 9 digits");
             setSeverity("error");
             setShowSnackbar(true);
        }
        else if (values.phone_number.length > 15){
            setSnackbarMessage("Phone Number should be at most 11 digits");
            setSeverity("error");
            setShowSnackbar(true);
        }
        else {
            handleAPIcall(values)
        }


    }
    const signUpForm = FORM.useForm({
        name: "Sign Up Request Form",
        validationSchema: schema,
        defaultValues: {email: "", company: "", phone_number: "",},
        onSubmitHandler,
    });

     const handleAPIcall = async (values: I_SignUpRequestFormValues) => {
         const endPoint = "/user/account/signup-email";
         const payload = {
             email: values.email,
             company: values.company,
             phone_number: values.phone_number,
         };
         await postAxiosAPI(endPoint, JSON.stringify(payload))
             .then((res: any) => {
                if (res.data.code !== 200) {
                    setSnackbarMessage(res.data.message);
                    setSeverity("error");
                    setShowSnackbar(true);
                } else {
                    setSnackbarMessage("SignUp Request Sent ");
                    setShowSnackbar(true);
                    setSeverity("success");
                }
             })
             .catch((err: any) => {
                 console.log(err);
                setSnackbarMessage("SignUp Request Failed ");
                setShowSnackbar(true);
                setSeverity("error");
             });
    };

    return (<>
        <FORM.Provider form={signUpForm.instance} heading={"Raise a sign up request."}>
            <Alert severity="info">
                <AlertTitle><b>Disclaimer</b></AlertTitle>
                We are currently accepting invitations. Kindly extend yours by completing the form below.
            </Alert>

            <FORM.Input
                {...signUpForm.bind({name: "email", label: "Email Id"})}
                inputOptions={{fullWidth: true}}
            />
            <FORM.Input
                {...signUpForm.bind({name: "company", label: "Company Name"})}
                inputOptions={{fullWidth: true}}
            />

            <MuiPhone
              placeholder= "Mobile Number"// Default country code (Portugal)
              value={phone}
              onChange={(phone:any) => {
                setPhone(phone);
                signUpForm.instance.setValue("phone_number", phone); // Update phone number in form
              }}
              style={{
                  '--react-international-phone-flag-width': '40px',
                  '--react-international-phone-flag-height': '32px',
                  '--react-international-phone-height': '50px',
                  '--react-international-phone-width': "full",
                  '--react-international-phone-font-size': '15px',
                  paddingLeft:"10px",
                  paddingTop:"12px",
                  paddingBottom:"12px",
                  width: "102%",
                   } as React.CSSProperties
                }
        />
            {/*import SendOutlinedIcon from '@mui/icons-material/SendOutlined';*/}
            <FORM.SubmitButton
                text={"I am ready to be onboarded"}
                iconName={"Send"}
                formInstance={signUpForm.instance}
                fullWidth={true}
            />
        </FORM.Provider>
        <MessageAlert
            showBar={showSnackbar}
            message={snackbarMessage}
            severity={severity}
            onClose={() => setShowSnackbar(false)}
        />
        </>);
};

